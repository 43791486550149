import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Icons from 'react-bootstrap-icons';

import { useIdentity } from "../../contexts/IdentityContext";
import AccountUpdateForm from "../forms/AccountUpdateForm";

const AccountModal = () => {
    const { authenticated, identity, logout } = useIdentity();
    const [showing, setShowing] = useState<boolean>(false);

    function hide() {
        setShowing(false);
    }

    function show() {
        setShowing(true);
    }

    if (!authenticated) return <></>

    return <>
        <Modal show={showing} onHide={hide} tabIndex={Number("-1")} aria-labelledby="accountModalLabel" aria-hidden="true">
            <Modal.Body>
                {authenticated && <AccountUpdateForm hide={hide} />}
            </Modal.Body>
        </Modal>
        <button className="btn-reset ff-tactic bg-purple-gradient px-3 py-2 br-30 me-lg-3 mb-1" onClick={show}>
            <span className="d-none d-xl-inline-block">
                <Icons.PersonFill />
            </span>{' '}
            <span className="d-inline-block d-xl-none">
                My Account
            </span>
            <span className="d-none d-xl-inline-block">
                {identity?.account.email}
            </span>
        </button>
        <Button variant="secondary" onClick={logout}>Logout</Button>
    </>
}

export default AccountModal;
