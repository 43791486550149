import { useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap"
import AccountModal from "./modals/AccountModal";
import LoginModal from "./modals/LoginModal";
import { useIdentity } from "../contexts/IdentityContext";

const iconImg = require('../assets/images/icon.png');
const logoImg = require('../assets/images/logo.png');

export const Header = () => {
    const { authenticated } = useIdentity();
    const location = useLocation();
    const navBg = location.pathname === "/info" ? 'bg-white' : 'bg-clear';

    return <header className="app-header">
        <Navbar expand={"lg"} className={`d-none d-xl-block ${navBg}`} fixed="top" variant="dark">
            <Container fluid>
                <Navbar.Brand href={"/"}>
                    <img src={iconImg} alt="Icon" height={48} />{' '}
                    <img src={logoImg} alt="Logo" height={32} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-between" id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/info">
                            <span className="ff-tactic bg-purple-gradient px-3 py-2 br-30">Project Info</span>
                        </Nav.Link>
                    </Nav>
                    <Navbar.Text>
                        {authenticated && <AccountModal />}
                        {!authenticated && <LoginModal />}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Navbar expand={"lg"} className={`d-none d-lg-block d-xl-none ${navBg}`} fixed="top" variant="dark">
            <Container fluid>
                <Navbar.Brand>
                    <img src={iconImg} alt="Icon" height={48} />{' '}
                    <img src={logoImg} alt="Logo" height={36} />
                </Navbar.Brand>
                <Nav>
                    <Nav.Link href="/" className="ff-tactic bg-purple-gradient px-3 py-2 br-30 me-3">
                        Home
                    </Nav.Link>
                    <Nav.Link href="/info" className="ff-tactic bg-purple-gradient px-3 py-2 br-30 me-3">
                        Project&nbsp;Info
                    </Nav.Link>
                    {authenticated && <AccountModal />}
                    {!authenticated && <LoginModal />}
                </Nav>
            </Container>
        </Navbar>
        <Navbar expand={"lg"} className={`d-block d-lg-none ${navBg}`} fixed="top" variant={navBg === "bg-white" ? "light" : "dark"}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <img src={iconImg} alt="Icon" height={48} className="me-3" />
                    <img src={logoImg} alt="Logo" height={32} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-between text-center py-2" id="basic-navbar-nav">
                    <Nav className="w-75 mx-auto">
                        <Nav.Link href="/info" className="ff-tactic bg-purple-gradient px-3 py-2 br-30 mb-1">
                            Project Info
                        </Nav.Link>
                        {authenticated && <AccountModal />}
                        {!authenticated && <LoginModal />}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header >
}

export const Footer = () => {
    return <footer className="app-footer">
        <Navbar className="bg-clear " fixed="bottom">
            <Container fluid>
            </Container>
        </Navbar>
    </footer>
}