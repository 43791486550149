import { jwtDecode as decodeToken } from 'jwt-decode';
import { api } from '../services/api'

require('dotenv').config();

const API_URL = process.env.REACT_APP_API_URL;

export async function refreshAccessToken() {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) throw new Error('No refresh token found');
        const response = await api.post(`${API_URL}/auth/refresh`, { refreshToken });
        localStorage.setItem('accessToken', response.data.accessToken);
    } catch (error) {
        console.error('Token refresh failed', error);
        // Handle logout or other actions if necessary
    }
}

export const parseToken = (token: string): Identity | null => {
    try {
        const decoded: any = decodeToken(token);
        return decoded;
    } catch (error) {
        console.error('Error decoding JWT token', error);
        return null;
    }
};
