import { useState } from "react";
import { Modal } from "react-bootstrap";

import { useIdentity } from "../../contexts/IdentityContext";
import LoginForm from "../forms/LoginForm";

const LoginModal = () => {
    const { authenticated } = useIdentity();
    const [showing, setShowing] = useState<boolean>(false);

    function hide() {
        setShowing(false);
    }

    function show() {
        setShowing(true);
    }

    if (authenticated) return <></>

    return <>
        <Modal show={showing} onHide={hide} tabIndex={Number("-1")} aria-labelledby="loginModalLabel" aria-hidden="true">
            <Modal.Body>
                <LoginForm hide={hide} />
            </Modal.Body>
        </Modal>
        <button className="btn-reset ff-tactic bg-purple-gradient px-3 py-2 br-30" onClick={show}>Login</button>
    </>
}

export default LoginModal;
