import b58 from 'bs58';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { getStorageValue, removeStorageValue, setStorageValue } from '../helpers/storage';
import { api } from '../services/api';
import { parseToken } from '../helpers/security';

const identityStorageKey = "edu-identity";

interface IdentityState {
  identity: Identity | undefined,
  authenticated: boolean,
  authenticating: boolean,
  login: (email: string, password: string, remember: boolean) => Promise<void>,
  register: (request: RegisterRequest) => Promise<void>,
  logout: () => void,
  update: (email: string, firstName: string, lastName: string, dob: string, password?: string) => Promise<void>,
  authenticateWallet: () => Promise<void>,
  resetIdentity: (redirect: boolean) => void
}

export const IdentityContext = createContext<IdentityState>({
  identity: undefined,
  authenticated: false,
  authenticating: false,
  login: async () => { },
  register: async () => { },
  logout: () => { },
  update: async () => { },
  authenticateWallet: async () => { },
  resetIdentity: () => { }
});

export const IdentityProvider = (props: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const { connected, disconnect, disconnecting, publicKey, signMessage } = useWallet();
  const { connection } = useConnection();
  const identityRef = useRef<Identity>();
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [userRejected, setUserRejected] = useState(false);

  const setIdentity = useCallback((tokens: {
    accessToken: string,
    refreshToken: string
  }) => {
    let decodedToken = parseToken(tokens.accessToken);
    if (!decodedToken) throw new Error("Invalid token");

    const identity = {
      account: decodedToken.account,
      profile: decodedToken.profile,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken
    };

    identityRef.current = identity;
    setStorageValue(identityStorageKey, identity);
    setAuthenticated(true);
    setAuthenticating(false);
  }, []);

  const resetIdentity = useCallback(() => {
    identityRef.current = undefined;
    removeStorageValue(identityStorageKey);
    setAuthenticated(false);
    setAuthenticating(false);

    if (connected) disconnect();
  }, [connected, disconnect]);

  const authenticateWallet = useCallback(async () => {
    if (authenticated || authenticating || disconnecting) return;
    if (!publicKey || !connected) return;
    if (!signMessage) return;

    setAuthenticating(true);
    setUserRejected(false);

    const message = publicKey.toBase58();
    const encodedMessage = new TextEncoder().encode(message);
    const signedMessage = await signMessage(encodedMessage);

    const data = {
      publicKey: b58.encode(publicKey.toBytes()),
      signedMessage: b58.encode(signedMessage),
      encodedMessage: b58.encode(encodedMessage)
    };

    api.post('/account/authenticate', data).then((response: any) => {
      setIdentity(response.data);
      setUserRejected(false);
    }).catch((error: any) => {
      if (error.name === "WalletSignMessageError")
        setUserRejected(true);
      console.log(error.message);
    }).finally(() => {
      setAuthenticating(false);
    });
  }, [authenticated, authenticating, connected, disconnecting, publicKey, setIdentity, signMessage]);

  const login = useCallback(async (email: string, password: string, remember: boolean) => {
    if (authenticated || authenticating) return;
    if (!email || !password) return;

    setAuthenticating(true);

    const data = {
      email,
      password,
      remember
    };

    api.post('/account/login', data).then((response: any) => {
      setIdentity(response.data);
    }).catch((error: any) => {
      console.log(error.message);
    }).finally(() => {
      setAuthenticating(false);
    });
  }, [authenticated, authenticating, setIdentity]);

  const register = useCallback(async (request: RegisterRequest) => {
    if (authenticated || authenticating) return;
    if (!request || !request.email || !request.password || !request.firstName || !request.lastName || !request.dob)
      return;

    setAuthenticating(true);

    api.post('/account/register', request).then((response: any) => {
      setIdentity(response.data);
    }).catch((error: any) => {
      console.log(error.message);
    }).finally(() => {
      setAuthenticating(false);
    });
  }, [authenticated, authenticating, setIdentity]);

  const update = useCallback(async (email: string, firstName: string, lastName: string, dob: string, password?: string) => {
    if (!authenticated || authenticating || !identityRef.current) return;
    if (!email || !firstName || !lastName || !dob) return;

    setAuthenticating(true);

    const data = {
      email,
      firstName,
      lastName,
      dob,
      password
    };

    api.post(`/account/${identityRef.current.account.id}/login`, data).then((response: any) => {
      setIdentity(response.data);
    }).catch((error: any) => {
      console.log(error.message);
    }).finally(() => {
      setAuthenticating(false);
    });
  }, [authenticated, authenticating, setIdentity]);

  const logout = useCallback(() => {
    if (disconnecting) return;
    if (!authenticated) return;
    resetIdentity();
    navigate('/');
  }, [disconnecting, authenticated, resetIdentity, navigate]);

  useEffect(() => {
    if (disconnecting) return;

    // If they aren't authenticated or in the process of authenticating, move forward.
    if (!authenticated && !authenticating) {
      // Let's check if they have an identity first
      getStorageValue(identityStorageKey).then((identity) => {
        if (identity) {
          setIdentity(identity);
        } else {
          resetIdentity();
        }
      }).catch((error: any) => {
        console.log(error.message);
      });
    }
  }, [authenticated, authenticating, connected, disconnecting, publicKey, userRejected, authenticateWallet, setIdentity, resetIdentity]);

  useEffect(() => {
    // Check if the user is authenticated and connected
    if (!disconnecting && authenticated && connected && publicKey && identityRef.current) {
      // We are connected, so let's throw a check in here
      connection.onAccountChange(
        publicKey,
        (updatedAccountInfo) => {
          if (updatedAccountInfo.owner === publicKey) return;
          resetIdentity();
          authenticateWallet();
        },
        'confirmed'
      )
    }
  }, [authenticated, connected, connection, disconnecting, publicKey, authenticateWallet, resetIdentity]);

  // Disconnecting, reset everything
  useEffect(() => {
    if (disconnecting) {
      resetIdentity();
    }
  }, [disconnecting, resetIdentity]);

  const context = useMemo(() => ({
    identity: identityRef.current,
    authenticated,
    authenticating,
    authenticateWallet,
    login,
    logout,
    update,
    register,
    resetIdentity
  }), [
    authenticated,
    authenticating,
    authenticateWallet,
    login,
    logout,
    update,
    register,
    resetIdentity
  ]);

  return (
    <IdentityContext.Provider value={context}>
      {props.children}
    </IdentityContext.Provider>
  )
}

export const useIdentity = () => useContext(IdentityContext);
