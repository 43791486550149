import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { useIdentity } from "../../contexts/IdentityContext";

const logo = require('../../assets/images/icon.png');

const AccountSettings = ({
    hide
}: {
    hide: () => void
}) => {
    const { publicKey } = useWallet();
    const { identity, update } = useIdentity();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [dob, setDob] = useState<string>('');
    const [password, setPassword] = useState<string>();

    useEffect(() => {
        if (identity && identity.account) {
            setEmail(identity.account.email);
            setFirstName(identity.profile.firstName);
            setLastName(identity.profile.lastName);
            setDob(new Date(identity.profile.dob).toISOString().split('T')[0]);
        }
    }, [identity, publicKey]);

    async function handleSubmit() {
        if (!loading || !identity) return;

        if (!email) {
            setError('Email is required');
        }

        if (!firstName) {
            setError('First name is required');
        }

        if (!lastName) {
            setError('Last name is required');
        }

        if (!dob) {
            setError('Birthdate is required');
        }

        setError(undefined);

        try {
            setLoading(true);

            await update(email, firstName, lastName, dob, password);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function updateEmail(event: any) {
        setEmail(event.currentTarget.value);
    }

    async function updateFirstName(event: any) {
        setFirstName(event.currentTarget.value);
    }

    async function updateLastName(event: any) {
        setLastName(event.currentTarget.value);
    }

    async function updateDob(event: any) {
        setDob(event.currentTarget.value);
    }

    async function updatePassword(event: any) {
        setPassword(event.currentTarget.value);
    }

    return <Form>
        <Row className="justify-content-between">
            <Col>
                <img src={logo} alt="EduBloc" height={42} />{' '}
                <span className="mt-1 ms-1 fs-5 ff-tactic">
                    My EduBloc Account
                </span>
            </Col>
            <Col xs={2} className="text-end">
                <Button variant="outline-secondary" onClick={hide}>
                    &times;
                </Button>
            </Col>
        </Row>
        <hr />
        <Row className="justify-content-center w-75 mx-auto">
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" defaultValue={email} onChange={updateEmail} required disabled={loading} />
                </Form.Group>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" defaultValue={firstName} onChange={updateFirstName} required disabled={loading} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" defaultValue={lastName} onChange={updateLastName} required disabled={loading} />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" value={dob} defaultValue={dob} onChange={updateDob} required disabled={loading} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={password} placeholder="Password" onChange={updatePassword} required disabled={loading} />
                </Form.Group>
                {error && (
                    <Form.Group>
                        <Form.Text>{error}</Form.Text>
                    </Form.Group>
                )}
            </Col>
        </Row>
        <hr />
        <Row className="text-end">
            <Col>
                <Button variant="edu" onClick={handleSubmit} disabled={loading}>Update</Button>{' '}
                <Button variant="secondary" onClick={hide}>Close</Button>
            </Col>
        </Row>
    </Form>
}

export default AccountSettings;
