import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useIdentity } from "../../contexts/IdentityContext";

const logo = require('../../assets/images/icon.png');

const RegisterForm = ({
    hide,
    hideTitle
}: {
    hide: () => void,
    hideTitle?: boolean
}) => {
    const { register } = useIdentity();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [dob, setDob] = useState<string>();

    async function handleSubmit(event: any) {
        event.preventDefault();

        if (loading) return;

        setError(undefined);

        if (!email) {
            setError("Email is required");
            return;
        }

        if (!password) {
            setError("Password is required");
            return;
        }

        if (!firstName) {
            setError("First name is required");
            return;
        }

        if (!lastName) {
            setError("Last name is required");
            return;
        }

        if (!dob) {
            setError("Date of birth is required");
            return;
        }

        try {
            setLoading(true);
            register({
                email,
                password,
                firstName,
                lastName,
                dob
            });
            hide();
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function updateEmail(event: any) {
        setEmail(event.currentTarget.value);
    }

    async function updatePassword(event: any) {
        setPassword(event.currentTarget.value);
    }

    async function updateFirstName(event: any) {
        setFirstName(event.currentTarget.value);
    }

    async function updateLastName(event: any) {
        setLastName(event.currentTarget.value);
    }

    async function updateDob(event: any) {
        setDob(event.currentTarget.value);
    }


    return <Form onSubmit={handleSubmit}>
        {!hideTitle && (<>
            <Row className="justify-content-center text-center">
                <Col>
                    <img src={logo} alt="EduBloc" height={72} />
                    <h2 className="my-3 ff-tactic">
                        EduBloc Registration
                    </h2>
                </Col>
            </Row>
            <hr />
        </>)}
        <Row className="justify-content-center w-lg-75 mx-auto">
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={firstName} onChange={updateFirstName} required disabled={loading} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={lastName} onChange={updateLastName} required disabled={loading} />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" value={dob} onChange={updateDob} required disabled={loading} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={updateEmail} required disabled={loading} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={updatePassword} required disabled={loading} />
                </Form.Group>
                {error && (
                    <Form.Group>
                        <Form.Text>
                            {error}
                        </Form.Text>
                    </Form.Group>
                )}
            </Col>
        </Row>
        <hr />
        <Row className="text-end">
            <Col>
                <Button variant="edu" type="submit" disabled={loading}>Register</Button>{' '}
                <Button variant="secondary" onClick={hide}>Close</Button>
            </Col>
        </Row>
    </Form>
}

export default RegisterForm;
