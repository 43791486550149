import { Col, Container, Row } from "react-bootstrap";

const iconImg = require('../assets/images/icon.png');
const logoImg = require('../assets/images/logo.png');

const graphics = [
    require('../assets/images/content/infographic1.jpg')
];

const InfoPage = () => {
    return (<Container className="mt-lg-5 pt-5 bg-white" id="info-page">
        <Row className="mb-0 mb-lg-5 pt-3 justify-content-center">
            <Col xs={12} lg={4} className="text-center p-0 pt-4 pt-lg-5 mb-3">
                <img src={graphics[0]} alt="What it is" className="d-none d-lg-inline-block mt-3" width={350} />
                <img src={graphics[0]} alt="What it is" className="d-inline-block d-lg-none" width={250} />
            </Col>
            <Col xs={12} md={10} lg={8}>
                <Row>
                    <Col>
                        <h3 className="ff-tactic text-center text-lg-start">What is EduBloc?</h3>
                    </Col>
                </Row>
                <hr className="bg-purple-gradient" />
                <Row>
                    <Col>
                        <p>
                            EduBloc is a cutting-edge education platform that harnesses the power of blockchain technology to revolutionize the way we learn and share knowledge. Built on the principles of decentralization and transparency, EduBloc aims to create a secure and immutable ecosystem for learners and educators alike.
                        </p>
                        <p>
                            At the heart of EduBloc lies a decentralized learning environment that empowers individuals to take control of their educational journey. Through the use of blockchain, EduBloc ensures the integrity and authenticity of educational records, making it easier for learners to showcase their achievements and for employers to verify credentials.
                        </p>
                        <p>
                            One of the key features of EduBloc is its ability to facilitate peer-to-peer learning and knowledge sharing. Educators from around the world can contribute their expertise and create immutable courses, while learners can access a vast array of educational resources, all while maintaining complete ownership and control over their data.
                        </p>
                        <p>
                            EduBloc also introduces innovative incentive mechanisms that reward both learners and educators for their contributions to the platform. Through the use of cryptocurrencies and smart contracts, EduBloc creates a self-sustaining ecosystem that incentivizes active participation and fosters a thriving community of lifelong learners.
                        </p>
                        <p>
                            With its decentralized architecture, EduBloc ensures that knowledge is accessible to all, regardless of geographical or socioeconomic barriers. By leveraging the power of blockchain technology, EduBloc is paving the way for a more equitable and inclusive educational landscape, empowering individuals to unlock their full potential and shape the future of learning.
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mb-5">
            <Col xs={12} className="d-inline-block d-md-none text-center mb-3">
                <hr className="mb-5" />
                <img src={iconImg} alt="How it works" height={300} width={300} />
            </Col>
            <Col xs={12} md={10} lg={8}>
                <Row>
                    <Col>
                        <h3 className="ff-tactic text-center text-lg-start">How does EduBloc work?</h3>
                    </Col>
                </Row>
                <hr className="bg-purple-gradient" />
                <Row>
                    <Col>
                        <p>
                            EduBloc leverages the power of blockchain technology to create a decentralized and transparent educational ecosystem. At its core, blockchain is a distributed ledger that records transactions in a secure and immutable manner. In the context of EduBloc, these transactions can represent various educational activities, such as course enrollments, certificate issuances, and knowledge sharing.
                        </p>
                        <p>
                            When a learner completes a course or earns a certification on EduBloc, the achievement is recorded on the blockchain as an immutable and tamper-proof record. This ensures the integrity and authenticity of educational credentials, making it easier for employers and institutions to verify the learner's achievements without relying on centralized authorities.
                        </p>
                        <p>
                            Furthermore, EduBloc can incorporate the concept of digital ownership and licensing for educational resources, such as books and course materials. By leveraging blockchain technology and smart contracts, authors and publishers can securely distribute their works while maintaining control over copyrights and licensing terms. Learners can then access and purchase these resources through the EduBloc platform, with all transactions and ownership records transparently recorded on the blockchain.
                        </p>
                        <p>
                            This innovative approach not only streamlines the distribution and licensing process but also ensures fair compensation for content creators and provides learners with a wide range of high-quality educational materials. Additionally, the decentralized nature of EduBloc eliminates the need for intermediaries, reducing costs and enabling more efficient resource allocation within the educational ecosystem.
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col className="d-none d-md-inline-block text-center p-0 pt-3">
                <img src={iconImg} className="mt-5" alt="How it works" width={300} />
            </Col>
        </Row>
        <Row className="mb-5 pb-3 justify-content-center">
            <Col className="text-center" xs={6}>
                <hr className="bg-purple-gradient" />
                <div className="my-0 my-lg-3">
                    <img src={iconImg} alt="Icon" height={48} />
                    <hr className="d-block d-lg-none btn-reset" />
                    <img src={logoImg} alt="Logo" height={28} />
                </div>
                <br />
                &copy; {new Date().getFullYear()} EduBloc. All rights reserved.
            </Col>
        </Row>
    </Container>)
}

export default InfoPage;