import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { useIdentity } from "../../contexts/IdentityContext";
import RegisterForm from "../forms/RegisterForm";

const RegisterModal = () => {
    const { authenticated } = useIdentity();
    const [showing, setShowing] = useState<boolean>(false);

    function hide() {
        setShowing(false);
    }

    function show() {
        setShowing(true);
    }

    if (authenticated) return <></>

    return <>
        <Modal show={showing} onHide={hide} tabIndex={Number("-1")} aria-labelledby="registerModalLabel" aria-hidden="true">
            <Modal.Body>
                <RegisterForm hide={hide} />
            </Modal.Body>
        </Modal>
        <Button variant="edu" onClick={show} size="lg" className="px-4 py-2 fs-4 ff-tactic">Join the Waitlist</Button>
    </>
}

export default RegisterModal;
