import ViewerTest from '../components/3dtest';
import RegisterModal from '../components/modals/RegisterModal';
import { useIdentity } from '../contexts/IdentityContext';

const model = require('../assets/models/block.obj');
const texture = require('../assets/models/block.png');

const HomePage = () => {
    const { authenticated } = useIdentity();

    return <div className='bg-black w-100 h-100'>
        <ViewerTest objFile={model} objTexture={texture} />
        {!authenticated && <div className="waitlist">
            <RegisterModal />
        </div>}
    </div>
}

export default HomePage;