import { Navigate, Route, Routes } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';

import HomePage from '../pages/Home';
import InfoPage from '../pages/Info';

const AppRouter = () => {
    const { connected, disconnect } = useWallet();

    const Disconnect = () => {
        if (connected) {
            disconnect();
        }
        return <></>;
    }
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/disconnect' element={<Disconnect />} />
            <Route path='/info' element={<InfoPage />} />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};
export default AppRouter;