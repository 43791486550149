import './styles/scss/global.scss';
import { Footer, Header } from './components/Layout';
import AppRouter from './components/AppRouter';

function App() {
  return (
    <div className="App">
      <Header />
      <AppRouter />
      <Footer />
    </div>
  );
}

export default App;
