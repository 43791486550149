import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useIdentity } from "../../contexts/IdentityContext";
import { Link } from "react-router-dom";

const logo = require('../../assets/images/icon.png');

const LoginForm = ({
    hide
}: {
    hide: () => void
}) => {
    const { login } = useIdentity();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [remember, setRemember] = useState<boolean>(false);

    async function handleSubmit(event: any) {
        event.preventDefault();

        if (loading) return;

        setError(undefined);

        if (!email) {
            setError("Email is required");
            return;
        }

        if (!password) {
            setError("Password is required");
            return;
        }

        try {
            setLoading(true);
            await login(email, password, remember);
            hide();
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function updateEmail(event: any) {
        setEmail(event.currentTarget.value);
    }

    async function updatePassword(event: any) {
        setPassword(event.currentTarget.value);
    }

    async function toggleRemember() {
        setRemember(!remember);
    }

    return <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center text-center">
            <Col>
                <img src={logo} alt="EduBloc" height={72} />
                <h2 className="my-3 ff-tactic">
                    EduBloc Login
                </h2>
            </Col>
        </Row>
        <hr />
        <Row className="justify-content-center w-lg-75 mx-auto">
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={updateEmail} required disabled={loading} />
                    {error && (<Form.Text>
                        {error}
                    </Form.Text>)}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={updatePassword} required disabled={loading} />
                </Form.Group>
                <Form.Group>
                    <Form.Check type="checkbox" label="Remember me" checked={remember} onChange={toggleRemember} />
                </Form.Group>
            </Col>
        </Row>
        <hr />
        <Row className="justify-content-between">
            <Col lg={4} className="pt-2">
                <Link className="d-none" to='/forgot-password'>Forgot password?</Link>
            </Col>
            <Col lg={6} className="text-end">
                <Button variant="edu" type="submit" disabled={loading}>Login</Button>{' '}
                <Button variant="secondary" onClick={hide}>Close</Button>
            </Col>
        </Row>
    </Form>
}

export default LoginForm;
