import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { StrictMode } from 'react';
import SolanaProvider from './providers/SolanaProvider';
import { IdentityProvider } from './contexts/IdentityContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <SolanaProvider>
        <IdentityProvider>
          <App />
        </IdentityProvider>
      </SolanaProvider>
    </BrowserRouter>
  </StrictMode>
);
