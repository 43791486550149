export function setStorageValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

export async function getStorageValue(key: string) {
    const value = await localStorage.getItem(key);
    if (value === null) return null;
    return JSON.parse(value);
}

export function removeStorageValue(key: string) {
    localStorage.removeItem(key);
}