import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

interface ThreeDViewerProps {
    objFile: string;
    objTexture: string;
}

const space = require('../assets/images/skybox/space/stars.png');

const ThreeDViewer: React.FC<ThreeDViewerProps> = ({ objFile, objTexture }) => {
    const mountRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mountRef.current) return;

        // Create a new scene
        const scene = new THREE.Scene();

        // Create a new camera
        const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
        camera.position.z = 7;

        // Create a new renderer
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // Add the renderer to the mount
        mountRef.current.appendChild(renderer.domElement);

        // Cube Texture Loader
        const loader = new THREE.CubeTextureLoader();
        const skybox = loader.load([
            space,
            space,
            space,
            space,
            space,
            space,
        ]);

        scene.background = skybox;

        // Add orbit controls
        const controls = new OrbitControls(camera, renderer.domElement);

        // Add lighting
        const ambientLight = new THREE.AmbientLight(0xffffff);
        scene.add(ambientLight);

        // Load the OBJ file
        const objLoader = new OBJLoader();
        const textureLoader = new THREE.TextureLoader();
        let model: THREE.Object3D | null = null;
        objLoader.load(
            objFile,
            (object) => {
                model = object;
                model.scale.set(1, 1, 1);
                model.position.set(0, -1, 0);
                model.rotation.set(0, 0, 0);

                // Apply texture to the model
                const texture = textureLoader.load(objTexture);
                object.traverse((child) => {
                    if ((child as THREE.Mesh).isMesh) {
                        (child as THREE.Mesh).material = new THREE.MeshBasicMaterial({ map: texture });
                    }
                });
                scene.add(object);
            },
            undefined,
            (error) => {
                console.error('An error occurred while loading the OBJ file:', error);
            }
        );

        // Render the scene
        const animate = () => {
            requestAnimationFrame(animate);
            controls.autoRotate = true;
            controls.autoRotateSpeed = 1;
            controls.update();

            /*// Rotate the model
            if (model) {
                model.rotation.y += 0.005;
            }*/
            renderer.render(scene, camera);
        };
        animate();

        // Handle window resize
        const handleWindowResize = () => {
            if (!mountRef.current) return;

            camera.aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleWindowResize);

        // Clean up event listener on unmount
        return () => {
            mountRef.current?.removeChild(renderer.domElement);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [objFile, objTexture]);

    return (
        <div
            ref={mountRef}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw'
            }}
        />
    );
};

export default ThreeDViewer;
